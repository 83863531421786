import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"

import "reveal.js/dist/reveal.css"
import "reveal.js/dist/theme/blood.css"

declare global {
  interface Window {
    // eslint-disable-next-line
    Reveal: any
    // eslint-disable-next-line
    RevealNotes: any
  }
}

const RevealWrappingDiv = styled.div`
  &.reveal {
    width: 100%;
    height: 50vh;
  }
`

const A11y: React.FC<GatsbyProps> = ({ location }) => {
  const divRef = useRef(null)

  useEffect(() => {
    const node = divRef.current
    function startPresentation(): void {
      const deck = new Reveal(node, {
        embedded: true,
        plugins: [window?.RevealNotes],
        transition: "concave",
        transitionSpeed: "slow",
      })

      deck.initialize()
    }

    ;(function checkRevealLoaded(): void {
      if (window?.Reveal && window?.RevealNotes) {
        startPresentation()
      } else {
        setTimeout(() => {
          checkRevealLoaded()
        }, 100)
      }
    })()
  }, [divRef])

  return (
    <StandardLayout location={location}>
      <Helmet>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.1.0/reveal.js"
          integrity="sha512-LGXpHNR8kKb3liBoiowLefRfx9BahbZ0FWE8vfTOV3vU4jD/9SpoyZQ49rc7gBwSzbFTZAaLCsSkpujb6ic+Og=="
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/reveal.js/4.1.0/plugin/notes/notes.min.js"
          integrity="sha512-FYeeQscKqibmYKr0+nE2+fN5prBsFwgjsBVwkrA88O6mN2+ai6EvRkSi6txuhXlWsyK1MUfoV+94+q6HLouJSQ=="
          crossOrigin="anonymous"
        ></script>
      </Helmet>
      <h1>A11y - What, Why, How</h1>
      <p>
        The goal of this presentation is to help humanize how we interact with
        the digital world. A fundamentally modern human experience shared by
        all. But like too many things in our world, not accessible to all.
      </p>
      <p>
        Join me to explore, the <strong>What</strong>, the <strong>Why</strong>{" "}
        and the <strong>How</strong>, of Digital Accessibility.
      </p>
      <RevealWrappingDiv ref={divRef} className="reveal">
        <div className="slides">
          <section>
            <h1 className="r-fit-text">A11y - What, Why, How</h1>
            <aside className="notes">
              <p>Hello Everyone!</p>
              <p>
                Today I&apos;m going to try and answer a What question, a Why
                question and a How question
              </p>
              <p>
                But my main goal is to leave you wanting to learn more about
                Accessibility
              </p>
            </aside>
          </section>
          <section>
            <h2>What is A11y?</h2>
            <aside className="notes">What is a-1-1-y</aside>
          </section>
          <section>
            <p>A11y</p>
            <p className="fragment fade-in-then-semi-out">Accessibility</p>
            <p className="fragment fade-up">
              A<span style={{ color: "#d82e43" }}>ccessibilit</span>y
            </p>
            <aside className="notes">
              <p>Also known as a-11-y</p>
              <p>Is shorthand for writing accessibility</p>
              <p>(Next)</p>
              <p>Because there are 11 characters between a and y</p>
              <p>(Next)</p>
              <p>This might seem pointless, but.</p>
              <p>Saves 9 more characters on twitter</p>
              <p>Makes it easier to type</p>
              <p>Makes it easier to spell</p>
            </aside>
          </section>
          <section>
            <h3>In a nutshell that&apos;s what A11y is</h3>
            <ul>
              <li className="fragment">Small changes in our understanding</li>
              <li className="fragment">With meaningful impact 🎯 for others</li>
            </ul>
            <aside className="notes">
              <p>
                Personally I struggle with spelling, and typing a-1-1-y makes it
                easier for me to communicate quickly.
              </p>
              <p>
                And that&apos;s what really matters, being able to communicate
                with each other.
              </p>
            </aside>
          </section>
          <section>
            <h3>What is A11y to websites</h3>
            <ul>
              <li className="fragment">
                It&apos;s thinking about <strong>All</strong> users
              </li>
            </ul>
          </section>
          <section>
            <h3>All Interactions</h3>
            <ul>
              <li className="fragment">Mouse 🐭</li>
              <li className="fragment">Touch 👆</li>
              <li className="fragment">Keyboard 🎹</li>
              <li className="fragment">Screen Readers 🔊</li>
            </ul>
          </section>
          <section>
            <h3>All Visions</h3>
            <ul>
              <li className="fragment">Poor Eyesights 👓</li>
              <li className="fragment">Colour Vision Deficiencies 🎨</li>
              <li className="fragment">Screen Glare ☀️</li>
            </ul>
          </section>
          <section data-transition="concave">
            <h3>All Differences</h3>
            <ul>
              <li className="fragment">Cognitive 🧠</li>
              <li className="fragment">Sensory 👀</li>
              <li className="fragment">Physical ♿</li>
            </ul>
          </section>
          <section
            data-transition="zoom-in concave-out"
            data-transition-speed="default"
          >
            <h3>Fundamentally</h3>
            <p className="fragment">It&apos;s about no barrier to access</p>
            <aside className="notes">
              <p>No barriers to the great things we make </p>
            </aside>
          </section>
          <section data-transition="concave-in convex-out">
            <h2>That Was The What</h2>
            <aside className="notes">
              <p>That was the what, now the why</p>
            </aside>
          </section>
          <section data-transition="convex-in concave-out">
            <h2 className="r-fit-text">Why should you care?</h2>
          </section>
          <section data-transition="concave-in zoom-out">
            <h3>In a nutshell</h3>
            <p>You should care because people matter 😁</p>
          </section>
          <section
            data-transition="zoom-in concave-out"
            data-transition-speed="default"
          >
            <h3>This Is A Hard Question</h3>
            <p className="fragment">
              &quot;Blind People Don&apos;t Drive Cars&quot;
            </p>
            <aside className="notes">
              <p>
                Over the years I have heard this question in many different
                forms.
              </p>
              <p>And the first form I ever heard it in was...</p>
              <p>For some context.</p>
              <p>
                A car company whose website I worked on got sued in the US for
                having a non-a11y site
              </p>
              <p>
                The agency I worked for then did a surface level push to improve
                a11y.
              </p>
              <p>
                But this question became cultural, and toxic towards the goal of
                a11y
              </p>
              <p>
                It wasn&apos;t uncommon to hear things like &quot;Why are we
                doing this? Blind People Don&apos;t Drive Cars&quot;
              </p>
              <p>I don&apos;t have an answer to that, but...</p>
            </aside>
          </section>
          <section>
            <p>But, I do have 3 different answers for you</p>
            <ul>
              <li className="fragment">A 😭 Answer</li>
              <li className="fragment">A 😐 Answer</li>
              <li className="fragment">A 🤩 Answer</li>
            </ul>
          </section>
          <section>
            <h2>A 😭 Answer</h2>
          </section>
          <section>
            <h2>Ableism</h2>
            <li className="fragment">
              A persons disability shouldn&apos;t matter.
            </li>
            <li className="fragment">Visual 👀</li>
            <li className="fragment">Mental 🧠</li>
            <li className="fragment">Physical ♿</li>
          </section>
          <section>
            <h2>The 😐 Answer</h2>
          </section>
          <section>
            <h2>Various Laws</h2>
            <li className="fragment">
              UK & EU has laws that affect the public sector 🏦
            </li>
            <li className="fragment">USA has laws that allow suing 👨‍⚖️</li>
            <li className="fragment">
              Lot&apos;s websites can ignore these laws 😐
            </li>
            <li className="fragment">But hopefully not for forever 🤩</li>
            <aside className="notes">
              <p>
                At the end of the day there will keep being more pressure on law
                makers to make things a11y.
              </p>
              <p>
                20% of the population has some form of disability, and history
                always trends towards equality.
              </p>
            </aside>
          </section>
          <section>
            <h2>The 🤩 Answer</h2>
          </section>
          <section>
            <h2>The Curb Cut Effect</h2>
            <img
              className="r-stretch"
              src="https://upload.wikimedia.org/wikipedia/commons/1/1c/Curb_cut_for_wheelchair_ramp_%28DSC_3500%29.jpg"
              alt="granite curb cut"
            />
            <p style={{ fontSize: "16px" }}>
              <a href="https://commons.wikimedia.org/wiki/File:Curb_cut_for_wheelchair_ramp_(DSC_3500).jpg">
                Nick-philly
              </a>
              ,{" "}
              <a href="https://creativecommons.org/licenses/by-sa/4.0">
                CC BY-SA 4.0
              </a>
              , via Wikimedia Commons
            </p>
            <aside className="notes">
              <p>
                Firstly, you wouldn&apos;t believe how hard it is to find a
                picture of a curb cut
              </p>
              <p>But if you didn&apos;t know the name. </p>
              <p>
                A curb cut its basically the ramp from the road to the path.
              </p>
            </aside>
          </section>
          <section>
            <h3>1945</h3>
            <ul>
              <li className="fragment">World War 2 has come to an end ⚔️</li>
              <li className="fragment">Lots of people came home broken 💔</li>
              <li className="fragment">
                And in the town of Kalamazoo, Michigan 🏙️
              </li>
              <li className="fragment">The First Curb Cut was built 👷</li>
            </ul>
            <aside className="notes">
              <p>So now a little history</p>
            </aside>
          </section>
          <section>
            <h3>1970</h3>
            <ul>
              <li className="fragment">
                People protested by making their own curb cuts 👩‍🎤
              </li>
              <li className="fragment">Taking jack hammers to the curbs ⛏️</li>
              <li className="fragment">Pouring concert to create ramps 👷</li>
              <li className="fragment">
                They broke down barriers to their movement 🤘
              </li>
            </ul>
            <aside className="notes">
              <p>Mostly in berkeley california, but also elsewhere in the US</p>
            </aside>
          </section>
          <section>
            <h3>1990</h3>
            <ul>
              <li className="fragment">Over 40 years later ⌛</li>
              <li className="fragment">Curb cuts become law in the US 👩‍⚖️</li>
            </ul>
          </section>
          <section>
            <h3>If you&apos;re like me</h3>
            <p>You never thought of curb cuts as assistance technology ♿</p>
            <aside className="notes">
              <p>That&apos;s because (next slide)</p>
            </aside>
          </section>
          <section>
            <h3>Curb Cuts help everyone</h3>
            <p className="fragment">Not only for the mobility impaired</p>
            <ul>
              <li className="fragment">Pushing a pram or trolley 🛒</li>
              <li className="fragment">Carrying anything heavy 🏋️‍♀️</li>
              <li className="fragment">Walking with joint pain ⚡</li>
              <li className="fragment">Getting home after a 🍻</li>
            </ul>
          </section>
          <section>
            <h3>That&apos;s the curb cut Effect</h3>
            <ul>
              <li className="fragment">Enabling access for some 😊</li>
              <li className="fragment">Enhances access for all 🥰</li>
            </ul>
            <aside className="notes">
              <p>
                The Curb cut took over 40 years, let&apos;s not let digital a11y
                take that long
              </p>
            </aside>
          </section>
          <section>
            <h3>Another Curb Cut Effect</h3>
          </section>
          <section>
            <h3>Closed Captions</h3>
            <ul>
              <li className="fragment">Made for the hearing impaired 🦻</li>
              <li className="fragment">
                Enables people to follow along easier 📖
              </li>
              <li className="fragment">
                Helps people with second languages ㊙️
              </li>
              <li className="fragment">
                Allows you to watch netflix with noisy people 📺
              </li>
            </ul>
          </section>
          <section>
            <h3>A11y Helps EVERYONE</h3>
            <p className="fragment">Because we all live in the same world</p>
          </section>
          <section>
            <h3>Why I Care</h3>
            <p className="fragment">
              I never want to make someone feel disabled by a product I helped
              deliver!
            </p>
            <aside className="notes">
              <p>I follow a lot of a11y stuff on twitter</p>
              <p>
                A long time ago I read a story of a daughter getting a call off
                her Dad in tears. Her blind Dad.
              </p>
              <p>
                He was calling because yet again, he couldn&apos;t fill in a
                form on a website
              </p>
              <p>
                Not because he didn&apos;t have the tools to, but because no one
                considered him
              </p>
              <p>
                And he was forced to feel disabled, and ask his child for help
              </p>
              <p>(Quote)</p>
            </aside>
          </section>
          <section data-transition="concave-in convex-out">
            <h2>That Was The Why</h2>
            <aside className="notes">
              <p>That was the why, now the how</p>
            </aside>
          </section>
          <section data-transition="convex-in concave-out">
            <h3>How can you help?</h3>
          </section>
          <section>
            <h3>Everyone</h3>
            <p className="fragment">Help normalize talking about a11y 🧑‍🤝‍🧑</p>
            <p className="fragment">
              Be a voice for those often not considered 🗣️
            </p>
          </section>
          <section>
            <h3>UX/UI People</h3>
            <p className="fragment">Here are a few things to keep in mind</p>
            <ul>
              <li className="fragment">Mouse 🐭 & Keyboard 🎹</li>
              <ul>
                <li className="fragment">
                  Element states, focus, hover, disabled
                </li>
              </ul>
              <li className="fragment">Touch 👆</li>
              <ul>
                <li className="fragment">Button sizes and clearance</li>
              </ul>
              <li className="fragment">Screen Readers 🔊</li>
              <ul>
                <li className="fragment">
                  Content order being consistent across devices
                </li>
              </ul>
            </ul>
            <aside className="notes">
              <p>I&apos;m sure most of this won&apos;t be new</p>
              <p>But this is only the tip of the iceberg</p>
              <p>So I encourage you to keep learning</p>
            </aside>
          </section>
          <section>
            <h3>Copy People</h3>
            <ul>
              <li className="fragment">
                How the screen reader should read visual UI 🔊
              </li>
              <li className="fragment">
                Express link purpose, by link text alone 🌟
              </li>
            </ul>
            <aside className="notes">
              <p>Icons, alt tags, null alt tags</p>
              <p>
                Screen readers often get a list of all the links on the page.
              </p>
              <p>You can empower that</p>
            </aside>
          </section>
          <section>
            <h3>Dev People</h3>
            <p className="fragment">
              The web starts out a11y, until we break it 💥
            </p>
            <p className="fragment">
              Push back and learn how not to break it! ✨
            </p>
            <ul>
              <li className="fragment">
                Aria is powerful, but can make things worse 👷
              </li>
              <li className="fragment">Use native over custom 🦄</li>
              <li className="fragment">
                Press Tab and understand the keyboard ⌨️
              </li>
              <li className="fragment">
                Learn to use screen readers & the a11y tree 👂
              </li>
            </ul>
            <aside className="notes">
              <p>
                A11y can be a tool that makes it easier to deliver, if thought
                about in the right way.
              </p>
            </aside>
          </section>
          <section>
            <h3>QA People</h3>
            <p className="fragment">Test with a screen reader 🔊</p>
            <p className="fragment">Test everything with a keyboard 🎹</p>
            <p className="fragment">
              Share your experience not just the bugs 🤝
            </p>
            <aside className="notes">
              <p>
                Be the advocate for the user, and teach others how a user might
                use our site.
              </p>
            </aside>
          </section>
          <section data-transition="concave-in convex-out">
            <section>
              <h3>There is a lot to learn</h3>
              <p className="fragment">A few places to start</p>
              <ul>
                <li className="fragment">
                  <a
                    style={{ color: "#d42d42" }}
                    href="https://www.w3.org/WAI/WCAG21/quickref"
                  >
                    https://www.w3.org/WAI/WCAG21/quickref
                  </a>
                </li>
                <li className="fragment">
                  <a
                    style={{ color: "#d42d42" }}
                    href="https://webaim.org/standards/wcag/checklist"
                  >
                    https://webaim.org/standards/wcag/checklist
                  </a>
                </li>
                <li className="fragment">
                  <a style={{ color: "#d42d42" }} href="https://a11y.me/">
                    https://a11y.me/
                  </a>
                </li>
                <li className="fragment">
                  <a
                    style={{ color: "#d42d42" }}
                    href="https://github.com/LiamMyles/A11y-Resources"
                  >
                    https://github.com/liammyles/a11y-resources
                  </a>
                </li>
              </ul>
              <aside className="notes">
                <p>
                  W-Cag quick ref is a great place to see all the guidelines
                </p>
                <p>
                  Web aim has a great checklist for understanding how to do an
                  audit
                </p>
                <p>A11y.me is a great list of places to learn from</p>
                <p>
                  Lastly, I have a repo with a list of a11y resources I try to
                  maintain
                </p>
              </aside>
            </section>
            <section>
              <h3 className={"r-fit-text"}>Don&apos;t learn alone</h3>
            </section>
            <section>
              <h3>A11y Guild</h3>
              <p className="fragment">
                I&apos;m going to be starting an a11y guild ✨
              </p>
              <p className="fragment">
                Expect an announcement in slack soon 🎉
              </p>
              <p className="fragment">Everyone is welcome to come along 😄</p>
            </section>
          </section>
          <section data-transition="convex-in concave-out">
            <h3>Questions?</h3>
            <aside className="notes">
              <p>
                That&apos;s my presentation, hopefully you enjoined the journey
              </p>
              <p>It&apos;s now question time</p>
            </aside>
          </section>
        </div>
      </RevealWrappingDiv>
      <a href="https://youtu.be/XHC8LM-C0Cw" target="_blank" rel="noreferrer">
        Watch me present this presentation on YouTube
      </a>
    </StandardLayout>
  )
}

export default A11y
